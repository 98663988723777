import React, { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Store } from "../Store";

const breadcrumb = {
  marginTop: "1rem",
  backgroundColor: "#2e2e2e",
  border: "0.5px solid white",
  borderRadius: "0.37rem",
};

function Breadcrumb() {
  const { state } = useContext(Store);
  const { mainTitle, subject, ID, evID } = state;
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  return (
    <ul className="breadcrumb" style={breadcrumb}>
      <i
        style={{
          paddingRight: "12px",
          paddingTop: "8px",
          fontSizze: "18px",
          cursor: "pointer",
          color: "#f5f5f5",
        }}
        className="fas fa-arrow-left"
        aria-hidden="true"
        onClick={() => {
          navigate(-1);
        }}
      ></i>
      <i
        style={{
          cursor: "pointer",
          fontSizze: "18px",
          color: "#f5f5f5",
          paddingRight: "16px",
          paddingTop: "8px",
        }}
        onClick={() => {
          navigate(1);
        }}
        className="fa fa-arrow-right"
        aria-hidden="true"
      ></i>
      <li style={{ cursor: "pointer" }} onClick={() => navigate("/theme/0")}>
        Home
      </li>
      {location.pathname !== "/evaluationFavourites" ? (
        <li
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/theme/${ID}`)}
        >
          {mainTitle}
        </li>
      ) : null}

      {location.pathname !== `/theme/${ID}` &&
      location.pathname !== `/theme/${id}` &&
      location.pathname !== "/evaluationFavourites" ? (
        <li
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/evaluationAnalysis/${evID}`)}
        >
          {subject.Name}
        </li>
      ) : null}
      {location.pathname !== `/evaluationDocument/${evID}` &&
      location.pathname !== `/theme/${ID}` &&
      location.pathname !== `/evaluationInformation/${evID}` &&
      location.pathname !== `/evaluationAnalysis/${evID}` ? (
        <li style={{ cursor: "pointer" }}>Favoriten</li>
      ) : null}
    </ul>
  );
}

export default Breadcrumb;
