import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import LoginScreen from "./Screens/LoginScreen";
import { useContext } from "react";
import React from "react";
import { Store } from "./Store";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen";
import EvaluationDocumentScreen from "./Screens/EvaluationDocumentScreen";
import EvaluationInformationScreen from "./Screens/EvaluationInformationScreen";
import EvaluationAnalysisScreen from "./Screens/EvaluationAnalysisScreen";
import FavoriteScreen from "./Screens/FavoriteScreen";
import "devextreme/dist/css/dx.light.css";
import PageNotFound from "./Components/pageNotFound";
import Breadcrumb from "./Components/BreadCrumb";
import { Col, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";

function App() {
  const { state, dispatch: ctxdispatch } = useContext(Store);
  const { userInfo } = state;
  const location = useLocation();
  const navigate = useNavigate();

  const signOutHandler = () => {
    ctxdispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
  };
  function getFavourite() {
    navigate(`/evaluationFavourites`);
  }

  return (
    <div className="site-container">
      <Navbar style={{ height: "67.34px" }} bg="dark" variant="dark">
        <Container fluid>
          <LinkContainer to="/">
            <Navbar.Brand href="/">
              <img
                alt=""
                src="/assets/images/logo2.png"
                width="100px"
                className="d-inline-block align-top"
              />{" "}
            </Navbar.Brand>
          </LinkContainer>
          <Nav className="d-flex justify-content-end">
            {location.pathname !== "/evaluationFavourites" &&
            location.pathname !== "/login" ? (
              <Navbar.Brand
                style={{
                  color: "#f5f5f5",
                  cursor: "pointer",
                }}
                onClick={getFavourite}
              >
                Favoriten
              </Navbar.Brand>
            ) : null}
            {userInfo && (
              <Navbar.Brand>
                <Link to="/login">
                  <Button
                    onClick={signOutHandler}
                    variant="outline-warning text"
                  >
                    Sign Out
                  </Button>
                </Link>
              </Navbar.Brand>
            )}
          </Nav>
        </Container>
      </Navbar>
      {location.pathname !== "/theme/0" &&
      location.pathname !== "/login" &&
      location.pathname !== "/theme/:id" ? (
        <Container fluid>
          <Row>
            <Col
              style={{
                height: "81px",
              }}
            >
              <Breadcrumb />
            </Col>
          </Row>
        </Container>
      ) : (
        <div
          style={{
            height: "20px",
          }}
        ></div>
      )}

      <main>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/theme/:id" element={<HomeScreen />} />
          <Route path="/" element={<Navigate to="/theme/0" />} />
          <Route
            path="/evaluationAnalysis/:id"
            element={<EvaluationAnalysisScreen />}
          />
          <Route
            path="/evaluationDocument/:id"
            element={<EvaluationDocumentScreen />}
          />
          <Route
            path="/evaluationInformation/:id"
            element={<EvaluationInformationScreen />}
          />
          <Route path="/evaluationFavourites" element={<FavoriteScreen />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
