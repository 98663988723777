import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Tab from "@material-ui/core/Tab";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import {
  Chart,
  Tooltip,
  CommonSeriesSettings,
  Series,
  ArgumentAxis,
  ValueAxis,
  Legend,
} from "devextreme-react/chart";
import VectorMap, {
  Layer,
  Size,
  ControlBar,
} from "devextreme-react/vector-map";
import { Store } from "../Store";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import LoadingBox from "../Components/LoadingBox";
import MessageBox from "../Components/MessageBox";
import AnalyisTheme from "../Components/AnalyisTheme";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import PieChart from "devextreme-react/pie-chart";
import { PolarChart } from "devextreme-react/polar-chart";
import ProgressiveImage from "react-progressive-graceful-image";

const reducer = (state, action) => {
  switch (action.type) {
    case "FRETCH_REQUEST":
      return { ...state, loading: true };
    case "FRETCH_REQUEST_DATA":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        elements: action.payload,
        loading: false,
        favourits: [],
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EvaluationAnalysisScreen() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo, mainTitle, subject, image, dataTheme, index } = state;
  const [currentIndex, setCurrentIndex] = useState(index);
  const [nextIndex, setNextIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [jsonData, setJsonData] = useState("");
  const [bar, setBar] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("0");
  const [description, setDescription] = useState("");
  const [numberOfTheme, setNumberOfTheme] = useState(0);
  const [imageChange, setImageChange] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  let index2;

  const [{ loading, elements, error }, dispatch] = useReducer(reducer, {
    elements: [],
    error: "",
    loading: true,
  });
  const addIndex = () => {
    if (nextIndex !== dataTheme.length - 1) {
      setPrevIndex(prevIndex + 1);
    }
  };

  const subIndex = () => {
    if (prevIndex !== 0) {
      setPrevIndex(prevIndex - 1);
    }
  };

  const handleClickLeft = () => {
    subIndex();
    setImageChange(dataTheme[prevIndex].DateiName);
    console.log(prevIndex);
  };

  const handleClickRight = () => {
    addIndex();
    setImageChange(dataTheme[prevIndex].DateiName);
    console.log(nextIndex);
  };
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTab("0");
  };

  const handlerWithClick = async (ss, desc, ThemenAuswertungenID, image) => {
    ctxDispatch({ type: "PUSH_INDEXOFTHEME", payload: ss });
    ctxDispatch({ type: "PUSH_IMAGE", payload: image });
    localStorage.setItem("image", image);
    index2 = index;
    setOpen(true);
    setImageChange(image);
    setDescription(desc);
    if (ss !== 0) {
      setPrevIndex(ss - 1);
    }
    const token = JSON.parse(localStorage.getItem("userInfo"));
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/GetAuswertung/${ThemenAuswertungenID}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log(data);
        setJsonData(JSON.parse(data[0].JsonDaten));
      } catch (err) {}
    };
    fetchData();
  };

  useEffect(() => {
    if (userInfo) {
      ctxDispatch({ type: "PUSH_evID", payload: id });
      localStorage.setItem("evID", id);
      const token = JSON.parse(localStorage.getItem("userInfo"));
      const fetchData = async () => {
        dispatch({ type: "FRETCH_REQUEST" });
        try {
          const result = await axios.get(
            `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/GetAuswertungen/${id}`,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          dispatch({ type: "FETCH_SUCCESS", payload: result.data });
          console.log(result.data);
          ctxDispatch({ type: "DATA_THEME", payload: result.data });
        } catch (err) {
          dispatch({ type: "FETCH_FAIL", payload: err.message });
        }
      };
      fetchData();
    } else {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  return (
    <div className="container-fluid">
      <Row>
        <Col md={2}>
          <h5 style={{ color: "#f5f5f5" }}>{mainTitle}</h5>
          <div
            style={{
              borderRadius: "0px",
              height: "8rem",
              backgroundColor: subject.backgroundColor,
              fontColor: subject.fontColor,
            }}
            className="card mt-3 "
          >
            <div className="card-body">
              <h5 className="card-title ">{subject.Name}</h5>
            </div>
          </div>
          <div className="pt-3 ">
            <ul className="list-group color-button mb-3">
              <button
                onClick={() => {
                  navigate(`/evaluationAnalysis/${id}`);
                }}
                style={{
                  padding: "8px 2px",
                  marginBottom: "2px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item"
              >
                - Auswertung -
              </button>
              <button
                onClick={() => {
                  navigate(`/evaluationDocument/${id}`);
                }}
                style={{
                  padding: "8px 2px",
                  marginBottom: "2px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item"
              >
                Dokumente
              </button>
              <button
                onClick={() => {
                  navigate(`/evaluationInformation/${id}`);
                }}
                style={{
                  padding: "8px 2px",
                  marginBottom: "2px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item pl-0"
              >
                Information
              </button>
            </ul>
          </div>
        </Col>
        <Col
          className="height-col"
          style={{
            position: "relative",
            overflowY: "auto",
            backgroundColor: "#5e5e5e",
            color: "#f5f5f5",
            marginBottom: "3px",
          }}
          md={8}
        >
          {loading ? (
            <div className="center ">
              <LoadingBox />
            </div>
          ) : error ? (
            <Container>
              <Container className="mt-3">
                <MessageBox variant="warning">{error}</MessageBox>
              </Container>
            </Container>
          ) : (
            <Container>
              <div className="mt-3">
                <Row>
                  {elements.length !== 0
                    ? elements.map((element, index) => (
                        <Col
                          key={element.ID}
                          sm={6}
                          lg={4}
                          className="mb-3 themes"
                        >
                          <AnalyisTheme
                            onClick={() =>
                              handlerWithClick(
                                index,
                                element.Beschreibun,
                                element.ThemenAuswertungenID,
                                element.DateiName
                              )
                            }
                            element={element}
                          />
                        </Col>
                      ))
                    : null}
                </Row>
              </div>
            </Container>
          )}
        </Col>
        <Col md={2}>
          <h5 style={{ color: "#f5f5f5" }} className="m-auto">
            Beschreibung
          </h5>
          <textarea
            value={description}
            disabled
            style={{
              backgroundColor: "#5e5e5e",
              color: "#f5f5f5",
              marginBottom: "1rem",
            }}
            className=" mt-3 form-control"
            id="textAreaExample1"
            rows="5"
          ></textarea>
        </Col>
      </Row>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#f5f5f5",
          },
        }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            bgcolor: "#2e2e2e",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            typography: "body1",
          }}
        >
          <TabContext value={selectedTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                bgcolor: "#2e2e2e",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  sx: { backgroundColor: "rgb(210, 210, 210,0.5)", height: 50 },
                }}
              >
                <Tab
                  style={{ color: "#f5f5f5" }}
                  label=" Bild"
                  value="0"
                  onClick={() => {
                    setBar(false);
                  }}
                />
                <Tab
                  style={{ color: "#f5f5f5" }}
                  label=" Interaktiv"
                  value="1"
                  onClick={() => {
                    setBar(true);
                  }}
                />
              </TabList>
            </Box>
            <Container>
              <Row>
                <Col></Col>
                <Col>
                  <TabPanel value="0">
                    <ProgressiveImage
                      src={`//www.geoware-gmbh.de/ViewerBackend_Showcase/api/Img/${imageChange}`}
                    >
                      {(src, loading) => (
                        <img
                          className={`image${loading ? " loading" : " loaded"}`}
                          src={src}
                          alt=""
                          width="1000"
                          height="auto"
                        />
                      )}
                    </ProgressiveImage>
                  </TabPanel>
                </Col>
                <Col></Col>
              </Row>
            </Container>
            <TabPanel value="1">
              <Row>
                {jsonData.Auswertungstyp === "Karte" ? (
                  <VectorMap
                    id="vMap"
                    title={jsonData.title}
                    adaptiveLayout={jsonData.adaptiveLayout}
                  >
                    <Layer
                      dataSource={jsonData.layers.dataSource}
                      label={jsonData.layers.label}
                      customize={function(elements) {
                        elements.forEach(function(element) {
                          element.applySettings({
                            color: element.attribute("Farbe"),

                            hoveredColor: "#00ffff",

                            selectedColor: "#ffffff",
                          });
                        });
                      }}
                    />
                    <Tooltip
                      enabled={true}
                      zIndex={13000}
                      border={{
                        visible: false,
                      }}
                      font={{
                        color: "white",
                      }}
                      format={{
                        type: "fixedPoint",
                        precision: 2,
                      }}
                      color={"#2e2e2e"}
                      customizeTooltip={function(arg) {
                        var name = arg.attribute("NAME");

                        var wert = arg.attribute("Wert");

                        return { text: "Name: " + name + "\nWert: " + wert };
                      }}
                    />
                    <Size height={"100%"} width={"100%"} />
                    <ControlBar enabled={true} />
                  </VectorMap>
                ) : jsonData.Auswertungstyp === "Diagramm" ? (
                  jsonData.Function === "dxPolarChart" ? (
                    <PolarChart
                      id="vMap"
                      useSpiderWeb={jsonData.useSpiderWeb}
                      panes={jsonData.panes}
                      isTornado={jsonData.isTornado}
                      title={jsonData.title}
                      adaptiveLayout={jsonData.adaptiveLayout}
                      argumentAxis={jsonData.argumentAxis}
                      valueAxis={jsonData.panes}
                      dataSource={jsonData.dataSource}
                      series={jsonData.series}
                      legend={jsonData.legend}
                      theme={jsonData.theme}
                    >
                      <Tooltip
                        enabled={true}
                        customizeTooltip={function customizeTooltip(pointInfo) {
                          return {
                            text: `${pointInfo.seriesName}<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
                          };
                        }}
                        zIndex={13000}
                        border={{
                          visible: false,
                        }}
                        font={{
                          color: "white",
                        }}
                        format={{
                          type: "fixedPoint",
                          precision: 2,
                        }}
                        color={"#2e2e2e"}
                      />
                      <Size width={"100%"} height={"100%"} />
                    </PolarChart>
                  ) : (
                    <Chart
                      id="vMap"
                      panes={jsonData.panes}
                      rotated={jsonData.rotated}
                      isTornado={jsonData.isTornado}
                      title={jsonData.title}
                      series={jsonData.series}
                      adaptiveLayout={jsonData.adaptiveLayout}
                      argumentAxis={jsonData.argumentAxis}
                      valueAxis={jsonData.valueAxis}
                      dataSource={jsonData.dataSource}
                      theme={jsonData.theme}
                      commonSeriesSettings={{
                        hoverStyle: {
                          hatching: {
                            opacity: 0.5,
                          },
                        },
                      }}
                    >
                      <CommonSeriesSettings />
                      <Size width={"100%"} height={"100%"} />
                      <Tooltip
                        enabled={true}
                        customizeTooltip={function customizeTooltip(pointInfo) {
                          return {
                            text: `${pointInfo.seriesName}<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
                          };
                        }}
                        zIndex={13000}
                        border={{
                          visible: false,
                        }}
                        font={{
                          color: "white",
                        }}
                        format={{
                          type: "fixedPoint",
                          precision: 2,
                        }}
                        color={"#2e2e2e"}
                      />
                    </Chart>
                  )
                ) : jsonData.Auswertungstyp === "Tornadodiagramm" ? (
                  jsonData.Content.map((chartData, index) => (
                    <Col key={index} lg={6}>
                      <div style={{ margin: "0px 5px" }}>
                        <Chart
                          id="vMap"
                          commonSeriesSettings={chartData.commonSeriesSettings}
                          rotated={chartData.rotated}
                          isTornado={chartData.isTornado}
                          title={chartData.title}
                          adaptiveLayout={chartData.adaptiveLayout}
                          argumentAxis={chartData.argumentAxis}
                          valueAxis={chartData.panes}
                          dataSource={chartData.dataSource}
                          series={chartData.series}
                          legend={chartData.legend}
                          theme={chartData.theme}
                        >
                          <Tooltip
                            enabled={true}
                            customizeTooltip={function customizeTooltip(
                              pointInfo
                            ) {
                              return {
                                text: `${pointInfo.seriesName}<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
                              };
                            }}
                            zIndex={13000}
                            border={{
                              visible: false,
                            }}
                            font={{
                              color: "white",
                            }}
                            format={{
                              type: "fixedPoint",
                              precision: 2,
                            }}
                            color={"#2e2e2e"}
                          />
                        </Chart>
                      </div>
                    </Col>
                  ))
                ) : jsonData.Auswertungstyp === "Blasendiagramm" ? (
                  <Chart
                    id="vMap"
                    isTornado={jsonData.isTornado}
                    dataSource={jsonData.dataSource}
                    title={jsonData.title}
                    panes={jsonData.panes}
                    theme={jsonData.theme}
                    commonSeriesSettings={{
                      hoverStyle: {
                        hatching: {
                          opacity: 0.5,
                        },
                      },
                    }}
                  >
                    <Tooltip
                      enabled={true}
                      customizeTooltip={function customizeTooltip(pointInfo) {
                        return {
                          text: `${pointInfo.seriesName}<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
                        };
                      }}
                      zIndex={13000}
                      cornerRadius={35}
                      border={{
                        visible: false,
                      }}
                      font={{
                        color: "white",
                      }}
                      format={{
                        type: "fixedPoint",
                        precision: 2,
                      }}
                      color={"#2e2e2e"}
                    />
                    <Size width={"100%"} height={"100%"} />
                    <Series
                      name={jsonData.series.name}
                      type={jsonData.series.type}
                      argumentField={jsonData.series.argumentField}
                      valueField={jsonData.series.valueField}
                      sizeField={jsonData.series.sizeField}
                      tagField={jsonData.series.tagField}
                    />
                    <ArgumentAxis
                      title={jsonData.argumentAxis.title}
                      constantLines={jsonData.argumentAxis.constantLines}
                    />
                    <ValueAxis
                      title={jsonData.valueAxis.title}
                      constantLines={jsonData.valueAxis.constantLines}
                    />
                    <Legend
                      verticalAlignment={jsonData.legend.verticalAlignment}
                      horizontalAlignment={jsonData.legend.horizontalAlignment}
                      itemTextPosition={jsonData.legend.itemTextPosition}
                      visible={jsonData.legend.visible}
                      font={jsonData.legend.font}
                    />
                  </Chart>
                ) : jsonData.Auswertungstyp === "Tortendiagramm" ? (
                  <PieChart
                    id="vMap"
                    isTornado={jsonData.isTornado}
                    dataSource={jsonData.dataSource}
                    title={jsonData.title}
                    panes={jsonData.panes}
                    theme={jsonData.theme}
                    palette={jsonData.palette}
                    commonSeriesSettings={{
                      hoverStyle: {
                        hatching: {
                          opacity: 0.5,
                        },
                      },
                    }}
                  >
                    <Tooltip
                      enabled={true}
                      customizeTooltip={function customizeTooltip(pointInfo) {
                        return {
                          text: `${pointInfo.seriesName}<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
                        };
                      }}
                      zIndex={13000}
                      border={{
                        visible: false,
                      }}
                      font={{
                        color: "white",
                      }}
                      format={{
                        type: "fixedPoint",
                        precision: 2,
                      }}
                      color={"#2e2e2e"}
                    />
                    <Size width={"100%"} height={"100%"} />
                    <Series
                      argumentField={jsonData.series.argumentField}
                      valueField={jsonData.series.valueField}
                      label={jsonData.series.label}
                    />
                    <ArgumentAxis
                      visible={jsonData.argumentAxis.visible}
                      label={jsonData.argumentAxis.label}
                      grid={jsonData.argumentAxis.grid}
                    />
                    <ValueAxis
                      visible={jsonData.valueAxis.visible}
                      label={jsonData.valueAxis.label}
                      grid={jsonData.valueAxis.grid}
                    />
                    <Legend
                      verticalAlignment={jsonData.legend.verticalAlignment}
                      horizontalAlignment={jsonData.legend.horizontalAlignment}
                      itemTextPosition={jsonData.legend.itemTextPosition}
                      visible={jsonData.legend.visible}
                      font={jsonData.legend.font}
                    />
                  </PieChart>
                ) : null}
              </Row>
            </TabPanel>
            {/* bar */}
            {true ? (
              <div
                className="socialWrap"
                style={{
                  position: "fixed",
                  height: "5rem",
                  width: "100%",
                  bottom: "0px",
                  backgroundColor: "#2e2e2e",
                }}
              >
                <div className="socialWrap">
                  <i
                    className="fas fa-arrow-circle-left"
                    style={{
                      marginRight: "7rem",
                      marginTop: "1.5rem",
                      cursor: "pointer",
                      fontSize: "32px",
                      justifyContent: "center",
                      color: "white",
                      display: "flex",
                    }}
                    onClick={handleClickLeft}
                  ></i>
                  {/* <i
                    className="fa-light fa-square"
                    style={{
                      marginRight: "7rem",
                      marginTop: "1.5rem",
                      cursor: "pointer",
                      fontSize: "32px",
                      justifyContent: "center",
                      color: "white",
                    }}
                    onClick={() => {
                      setJsonData(dataItem);
                    }}
                  ></i> */}
                  <i
                    className="fas fa-arrow-circle-right"
                    onClick={handleClickRight}
                    style={{
                      cursor: "pointer",
                      marginTop: "1.5rem",
                      fontSize: "32px",
                      justifyContent: "center",
                      color: "white",
                    }}
                  ></i>
                </div>
              </div>
            ) : null}
          </TabContext>
        </Box>
      </Dialog>
    </div>
  );
}

export default EvaluationAnalysisScreen;
