import { createContext, useReducer } from "react";
import React from "react";

export const Store = createContext();

const intialState = {
  index: 0,
  dataTheme: null,
  ID: localStorage.getItem("ID") ? localStorage.getItem("ID") : null,
  image: localStorage.getItem("image") ? localStorage.getItem("image") : null,
  mainTitle: localStorage.getItem("mainTitle")
    ? localStorage.getItem("mainTitle")
    : null,

  subject: {
    backgroundColor: localStorage.getItem("backgroundColor")
      ? localStorage.getItem("backgroundColor")
      : null,
    fontColor: localStorage.getItem("fontColor")
      ? localStorage.getItem("fontColor")
      : null,
    Name: localStorage.getItem("Name") ? localStorage.getItem("Name") : null,
  },

  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
  evID: localStorage.getItem("evID") ? localStorage.getItem("evID") : null,
};

function reducer(state, action) {
  switch (action.type) {
    case "USER_SIGNIN":
      return { ...state, userInfo: action.payload };
    case "USER_SIGNOUT":
      return { ...state, userInfo: null };
    case "PUSH_MAIN_TITLE":
      return { ...state, mainTitle: action.payload };
    case "PUSH_SUBJECT":
      return { ...state, subject: action.payload };
    case "PUSH_IMAGE":
      return { ...state, image: action.payload };
    case "PUSH_ID":
      return { ...state, ID: action.payload };
    case "PUSH_evID":
      return { ...state, evID: action.payload };
    case "DATA_THEME":
      return { ...state, dataTheme: action.payload };
    case "PUSH_INDEXOFTHEME":
      return { ...state, index: action.payload };
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, intialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
