import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../Store";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dialog from "@mui/material/Dialog";
import Tab from "@material-ui/core/Tab";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Container from "react-bootstrap/Container";
import LoadingBox from "../Components/LoadingBox";
import MessageBox from "../Components/MessageBox";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import PieChart from "devextreme-react/pie-chart";
import { PolarChart } from "devextreme-react/polar-chart";
import VectorMap, {
  Layer,
  Size,
  ControlBar,
  Tooltip,
} from "devextreme-react/vector-map";
import {
  Chart,
  CommonSeriesSettings,
  Series,
  ArgumentAxis,
  ValueAxis,
  Legend,
} from "devextreme-react/chart";
import DocumentTheme from "../Components/DocumentTheme";
import Slide from "@mui/material/Slide";
import AnalyisTheme from "../Components/AnalyisTheme";
import axios from "axios";
import ProgressiveImage from "react-progressive-graceful-image";

const reducer = (state, action) => {
  switch (action.type) {
    case "FRETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        favouriteAnaylais: action.payload,
        favouriteDocument: [],
      };
    case "FETCH_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "FETCH_SUCCESS_DOCUMENT":
      return {
        ...state,
        loading: false,
        favouriteDocument: action.payload,
        favouriteAnaylais: [],
      };
    default:
      return state;
  }
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FavoriteScreen() {
  const [dataItemLeft, setDataItemLeft] = useState("");
  const [dataItem, setDataItem] = useState("");
  const [dataItemRight, setDataItemRight] = useState("");
  const [bar, setBar] = useState(false);
  const [jsonData, setJsonData] = useState("");
  const [selectedTab, setSelectedTab] = useState("0");
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [isClicked, setIsClicked] = useState(true);
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo, mainTitle, subject, image } = state;
  const navigate = useNavigate();
  const [
    { loading, error, favouriteAnaylais, favouriteDocument },
    dispatch,
  ] = useReducer(reducer, {
    favouriteAnaylais: [],
    favouriteDocument: [],
    error: "",
    loading: true,
  });

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTab("0");
    setBar(false);
  };

  const handlerWithClick = (desc, ThemenAuswertungenID, image) => {
    ctxDispatch({ type: "PUSH_IMAGE", payload: image });
    setOpen(true);
    setDescription(desc);
    const token = JSON.parse(localStorage.getItem("userInfo"));
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/GetAuswertung/${ThemenAuswertungenID}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setJsonData(JSON.parse(data[0].JsonDaten));
      } catch (err) {}
    };
    const fetchArrayData = async () => {
      try {
        const { data } = await axios.get(
          `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/GetAuswertung/${ThemenAuswertungenID}/WithPrevAndNext`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log(data);
        console.log(" data data");
        if (data.length === 2) {
          if (data[0].CustomSortNr === -1) {
            setDataItemLeft(JSON.parse(data[0].JsonDaten));
            setDataItem(JSON.parse(data[1].JsonDaten));
            console.log(dataItemLeft);
            console.log(dataItem);
          } else if (data[0].CustomSortNr === 0) {
            setDataItem(JSON.parse(data[0].JsonDaten));
            setDataItemRight(JSON.parse(data[1].JsonDaten));

            console.log(dataItem);
            console.log(dataItemRight);
          }
        } else {
          setDataItemLeft(JSON.parse(data[0].JsonDaten));
          setDataItem(JSON.parse(data[1].JsonDaten));
          setDataItemRight(JSON.parse(data[2].JsonDaten));
          console.log(dataItemLeft);
          console.log(dataItem);
          console.log(dataItemRight);
        }
      } catch (err) {}
    };
    fetchData();
    fetchArrayData();
  };

  function getFavouriteDocument() {
    const token = JSON.parse(localStorage.getItem("userInfo"));
    const fetchData = async () => {
      dispatch({ type: "FRETCH_REQUEST" });
      try {
        const { data } = await axios.get(
          `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/GetDokumente/0/Favorit`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log(data);
        dispatch({ type: "FETCH_SUCCESS_DOCUMENT", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: err.message });
      }
    };
    fetchData();
    setIsClicked(false);
  }

  function getFavouriteAnaylais() {
    const token = JSON.parse(localStorage.getItem("userInfo"));
    const fetchData = async () => {
      dispatch({ type: "FRETCH_REQUEST" });
      try {
        const { data } = await axios.get(
          `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/GetAuswertungen/0/Favorit`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log(data);
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: err.message });
      }
    };
    fetchData();
    setIsClicked(true);
  }

  useEffect(() => {
    if (userInfo) {
      getFavouriteAnaylais();
    } else {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  return (
    <div className="container-fluid">
      <Row>
        <Col md={2}>
          {/* <h5 style={{ color: "#f5f5f5" }}>{mainTitle}</h5>
          <div
            style={{
              borderRadius: "0px",
              height: "8rem",
              backgroundColor: subject.backgroundColor,
              fontColor: subject.fontColor,
            }}
            className="card mt-3 "
          >
            <div className="card-body">
              <h5 className="card-title ">{subject.Name}</h5>
            </div>
          </div> */}
          <div className="pt-3 ">
            <ul className="list-group color-button mb-3">
              <button
                onClick={() => {
                  getFavouriteAnaylais();
                }}
                style={{
                  padding: "8px 2px",
                  marginBottom: "2px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item"
              >
                {isClicked ? "- Auswertung - " : "Auswertung"}
              </button>
              {/* <button
                onClick={() => getFavouriteDocument()}
                style={{
                  padding: "8px 2px",
                  marginBottom: "2Px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item"
              >
                {isClicked ? "Dokumente" : "- Dokumente -"}
              </button> */}
            </ul>
          </div>
        </Col>
        <Col className="height-height-col-favorite" md={8}>
          {isClicked ? (
            <div
              className="height-col-favorite"
              style={{
                position: "relative",
                backgroundColor: "#5e5e5e",
                color: "#f5f5f5",
                display: "flex",
                overflow: "auto",
                marginBottom: "3px",
              }}
            >
              {loading ? (
                <Container className="mt-3">
                  <Row>
                    <Col md={12}>
                      <div className="center">
                        <LoadingBox />
                      </div>
                    </Col>
                  </Row>
                </Container>
              ) : error ? (
                <Container className="mt-3">
                  <MessageBox variant="warning">{error}</MessageBox>
                </Container>
              ) : (
                <Container>
                  {favouriteAnaylais.length !== 0 ? (
                    <div className="mt-3">
                      <Row>
                        {favouriteAnaylais.map((element) => (
                          <Col key={element.ID} lg={4} className="mb-3 themes">
                            <AnalyisTheme
                              onClick={() =>
                                handlerWithClick(
                                  element.Beschreibung,
                                  element.ThemenAuswertungenID,
                                  element.DateiName
                                )
                              }
                              element={element}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ) : null}
                </Container>
              )}
            </div>
          ) : (
            <div
              className="height-col-favorite"
              style={{
                position: "relative",
                backgroundColor: "#5e5e5e",
                color: "#f5f5f5",
                display: "flex",
                overflow: "auto",
                marginBottom: "3px",
              }}
            >
              {loading ? (
                <Container className="mt-3">
                  <Row>
                    <Col md={12}>
                      <div className="center">
                        <LoadingBox />
                      </div>
                    </Col>
                  </Row>
                </Container>
              ) : error ? (
                <Container className="mt-3">
                  <MessageBox variant="warning">{error}</MessageBox>
                </Container>
              ) : (
                <Container>
                  {favouriteDocument.length !== 0 ? (
                    <div className="mt-3">
                      <Row>
                        {favouriteDocument.map((document) => (
                          <Col
                            key={document.ID}
                            md={6}
                            sm={12}
                            className="mb-3"
                          >
                            <DocumentTheme document={document} />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ) : null}
                </Container>
              )}
            </div>
          )}
        </Col>
        <Col md={2}>
          <h5 style={{ color: "#f5f5f5" }} className="m-auto">
            Beschreibung
          </h5>
          <textarea
            value={description}
            disabled
            style={{ backgroundColor: "#5e5e5e", color: "#f5f5f5" }}
            className="mb-3 mt-3 form-control"
            id="textAreaExample1"
            rows="5"
          ></textarea>
        </Col>
      </Row>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#f5f5f5",
          },
        }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            bgcolor: "#2e2e2e",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            typography: "body1",
          }}
        >
          <TabContext value={selectedTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                bgcolor: "#2e2e2e",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  sx: { backgroundColor: "rgb(210, 210, 210,0.5)", height: 50 },
                }}
              >
                <Tab
                  style={{ color: "#f5f5f5" }}
                  label=" Bild"
                  value="0"
                  onClick={() => {
                    setBar(false);
                  }}
                />
                <Tab
                  style={{ color: "#f5f5f5" }}
                  label=" Interaktiv"
                  value="1"
                  onClick={() => {
                    setBar(true);
                  }}
                />
              </TabList>
            </Box>
            <Container>
              <Row>
                <Col></Col>
                <Col>
                  <TabPanel value="0">
                    <ProgressiveImage
                      src={`//www.geoware-gmbh.de/ViewerBackend_Showcase/api/Img/${image}`}
                    >
                      {(src, loading) => (
                        <img
                          className={`image${loading ? " loading" : " loaded"}`}
                          src={src}
                          alt=""
                          width="1000"
                          height="auto"
                        />
                      )}
                    </ProgressiveImage>
                  </TabPanel>
                </Col>
                <Col></Col>
              </Row>
            </Container>
            <TabPanel value="1">
              <Row>
                {jsonData.Auswertungstyp === "Karte" ? (
                  <VectorMap
                    id="vMap"
                    title={jsonData.title}
                    adaptiveLayout={jsonData.adaptiveLayout}
                  >
                    <Layer
                      dataSource={jsonData.layers.dataSource}
                      label={jsonData.layers.label}
                      customize={function(elements) {
                        elements.forEach(function(element) {
                          element.applySettings({
                            color: element.attribute("Farbe"),

                            hoveredColor: "#00ffff",

                            selectedColor: "#ffffff",
                          });
                        });
                      }}
                    />
                    <Tooltip
                      enabled={true}
                      zIndex={13000}
                      border={{
                        visible: false,
                      }}
                      font={{
                        color: "white",
                      }}
                      format={{
                        type: "fixedPoint",
                        precision: 2,
                      }}
                      color={"#2e2e2e"}
                      customizeTooltip={function(arg) {
                        var name = arg.attribute("NAME");

                        var wert = arg.attribute("Wert");

                        return { text: "Name: " + name + "\nWert: " + wert };
                      }}
                    />
                    <Size height={"100%"} width={"100%"} />
                    <ControlBar enabled={true} />
                  </VectorMap>
                ) : jsonData.Auswertungstyp === "Diagramm" ? (
                  jsonData.Function === "dxPolarChart" ? (
                    <PolarChart
                      id="vMap"
                      useSpiderWeb={jsonData.useSpiderWeb}
                      panes={jsonData.panes}
                      isTornado={jsonData.isTornado}
                      title={jsonData.title}
                      adaptiveLayout={jsonData.adaptiveLayout}
                      argumentAxis={jsonData.argumentAxis}
                      valueAxis={jsonData.panes}
                      dataSource={jsonData.dataSource}
                      series={jsonData.series}
                      legend={jsonData.legend}
                      theme={jsonData.theme}
                    >
                      <Tooltip
                        enabled={true}
                        customizeTooltip={function customizeTooltip(pointInfo) {
                          return {
                            text: `${pointInfo.seriesName}<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
                          };
                        }}
                        zIndex={13000}
                        border={{
                          visible: false,
                        }}
                        font={{
                          color: "white",
                        }}
                        format={{
                          type: "fixedPoint",
                          precision: 2,
                        }}
                        color={"#2e2e2e"}
                      />
                      <Size width={"100%"} height={"100%"} />
                    </PolarChart>
                  ) : (
                    <Chart
                      id="vMap"
                      panes={jsonData.panes}
                      rotated={jsonData.rotated}
                      isTornado={jsonData.isTornado}
                      title={jsonData.title}
                      series={jsonData.series}
                      adaptiveLayout={jsonData.adaptiveLayout}
                      argumentAxis={jsonData.argumentAxis}
                      valueAxis={jsonData.valueAxis}
                      dataSource={jsonData.dataSource}
                      theme={jsonData.theme}
                      commonSeriesSettings={{
                        hoverStyle: {
                          hatching: {
                            opacity: 0.5,
                          },
                        },
                      }}
                    >
                      <CommonSeriesSettings />
                      <Size width={"100%"} height={"100%"} />
                      <Tooltip
                        enabled={true}
                        customizeTooltip={function customizeTooltip(pointInfo) {
                          return {
                            text: `${pointInfo.seriesName}<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
                          };
                        }}
                        zIndex={13000}
                        border={{
                          visible: false,
                        }}
                        font={{
                          color: "white",
                        }}
                        format={{
                          type: "fixedPoint",
                          precision: 2,
                        }}
                        color={"#2e2e2e"}
                      />
                    </Chart>
                  )
                ) : jsonData.Auswertungstyp === "Tornadodiagramm" ? (
                  jsonData.Content.map((chartData, index) => (
                    <Col key={index} lg={6}>
                      <div style={{ margin: "0px 5px" }}>
                        <Chart
                          id="vMap"
                          commonSeriesSettings={chartData.commonSeriesSettings}
                          rotated={chartData.rotated}
                          isTornado={chartData.isTornado}
                          title={chartData.title}
                          adaptiveLayout={chartData.adaptiveLayout}
                          argumentAxis={chartData.argumentAxis}
                          valueAxis={chartData.panes}
                          dataSource={chartData.dataSource}
                          series={chartData.series}
                          legend={chartData.legend}
                          theme={chartData.theme}
                        >
                          <Tooltip
                            enabled={true}
                            customizeTooltip={function customizeTooltip(
                              pointInfo
                            ) {
                              return {
                                text: `${pointInfo.seriesName}<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
                              };
                            }}
                            zIndex={13000}
                            border={{
                              visible: false,
                            }}
                            font={{
                              color: "white",
                            }}
                            format={{
                              type: "fixedPoint",
                              precision: 2,
                            }}
                            color={"#2e2e2e"}
                          />
                        </Chart>
                      </div>
                    </Col>
                  ))
                ) : jsonData.Auswertungstyp === "Blasendiagramm" ? (
                  <Chart
                    id="vMap"
                    isTornado={jsonData.isTornado}
                    dataSource={jsonData.dataSource}
                    title={jsonData.title}
                    panes={jsonData.panes}
                    theme={jsonData.theme}
                    commonSeriesSettings={{
                      hoverStyle: {
                        hatching: {
                          opacity: 0.5,
                        },
                      },
                    }}
                  >
                    <Tooltip
                      enabled={true}
                      customizeTooltip={function customizeTooltip(pointInfo) {
                        return {
                          text: `${pointInfo.seriesName}<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
                        };
                      }}
                      zIndex={13000}
                      cornerRadius={35}
                      border={{
                        visible: false,
                      }}
                      font={{
                        color: "white",
                      }}
                      format={{
                        type: "fixedPoint",
                        precision: 2,
                      }}
                      color={"#2e2e2e"}
                    />
                    <Size width={"100%"} height={"100%"} />
                    <Series
                      name={jsonData.series.name}
                      type={jsonData.series.type}
                      argumentField={jsonData.series.argumentField}
                      valueField={jsonData.series.valueField}
                      sizeField={jsonData.series.sizeField}
                      tagField={jsonData.series.tagField}
                    />
                    <ArgumentAxis
                      title={jsonData.argumentAxis.title}
                      constantLines={jsonData.argumentAxis.constantLines}
                    />
                    <ValueAxis
                      title={jsonData.valueAxis.title}
                      constantLines={jsonData.valueAxis.constantLines}
                    />
                    <Legend
                      verticalAlignment={jsonData.legend.verticalAlignment}
                      horizontalAlignment={jsonData.legend.horizontalAlignment}
                      itemTextPosition={jsonData.legend.itemTextPosition}
                      visible={jsonData.legend.visible}
                      font={jsonData.legend.font}
                    />
                  </Chart>
                ) : jsonData.Auswertungstyp === "Tortendiagramm" ? (
                  <PieChart
                    id="vMap"
                    isTornado={jsonData.isTornado}
                    dataSource={jsonData.dataSource}
                    title={jsonData.title}
                    panes={jsonData.panes}
                    theme={jsonData.theme}
                    palette={jsonData.palette}
                    commonSeriesSettings={{
                      hoverStyle: {
                        hatching: {
                          opacity: 0.5,
                        },
                      },
                    }}
                  >
                    <Tooltip
                      enabled={true}
                      customizeTooltip={function customizeTooltip(pointInfo) {
                        return {
                          text: `${pointInfo.seriesName}<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
                        };
                      }}
                      zIndex={13000}
                      border={{
                        visible: false,
                      }}
                      font={{
                        color: "white",
                      }}
                      format={{
                        type: "fixedPoint",
                        precision: 2,
                      }}
                      color={"#2e2e2e"}
                    />
                    <Size width={"100%"} height={"100%"} />
                    <Series
                      argumentField={jsonData.series.argumentField}
                      valueField={jsonData.series.valueField}
                      label={jsonData.series.label}
                    />
                    <ArgumentAxis
                      visible={jsonData.argumentAxis.visible}
                      label={jsonData.argumentAxis.label}
                      grid={jsonData.argumentAxis.grid}
                    />
                    <ValueAxis
                      visible={jsonData.valueAxis.visible}
                      label={jsonData.valueAxis.label}
                      grid={jsonData.valueAxis.grid}
                    />
                    <Legend
                      verticalAlignment={jsonData.legend.verticalAlignment}
                      horizontalAlignment={jsonData.legend.horizontalAlignment}
                      itemTextPosition={jsonData.legend.itemTextPosition}
                      visible={jsonData.legend.visible}
                      font={jsonData.legend.font}
                    />
                  </PieChart>
                ) : null}
              </Row>
            </TabPanel>
            {/* bar */}
            {true ? (
              <div
                className="socialWrap"
                style={{
                  position: "fixed",
                  height: "5rem",
                  width: "100%",
                  bottom: "0px",
                  backgroundColor: "#2e2e2e",
                }}
              >
                <div className="socialWrap">
                  <i
                    className="fas fa-arrow-circle-left"
                    style={{
                      marginRight: "7rem",
                      marginTop: "1.5rem",
                      cursor: "pointer",
                      fontSize: "32px",
                      justifyContent: "center",
                      color: "white",
                      display: "flex",
                    }}
                    onClick={() => {
                      setJsonData(dataItemLeft);
                    }}
                  ></i>
                  {/* <i
                    className="fa-light fa-square"
                    style={{
                      marginRight: "7rem",
                      marginTop: "1.5rem",
                      cursor: "pointer",
                      fontSize: "32px",
                      justifyContent: "center",
                      color: "white",
                    }}
                    onClick={() => {
                      setJsonData(dataItem);
                    }}
                  ></i> */}
                  <i
                    className="fas fa-arrow-circle-right"
                    style={{
                      cursor: "pointer",
                      marginTop: "1.5rem",
                      fontSize: "32px",
                      justifyContent: "center",
                      color: "white",
                    }}
                    onClick={() => {
                      setJsonData(dataItemRight);
                    }}
                  ></i>
                </div>
              </div>
            ) : null}
          </TabContext>
        </Box>
      </Dialog>
    </div>
  );
}

export default FavoriteScreen;
