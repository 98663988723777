import axios from "axios";
import LoadingBox from "../Components/LoadingBox";
import MessageBox from "../Components/MessageBox";
import React, { useContext, useEffect, useReducer } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet-async";
import { Store } from "../Store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Theme from "../Components/Theme";

const reducer = (state, action) => {
  switch (action.type) {
    case "FRETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        themes: action.payload,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function HomeScreen() {
  const [{ loading, themes, error }, dispatch] = useReducer(reducer, {
    themes: [],
    error: "",
    loading: true,
  });
  const location = useLocation();
  const { state } = useContext(Store);
  const { userInfo, mainTitle } = state;
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    if (userInfo) {
      console.log(window.history.length);
      const token = JSON.parse(localStorage.getItem("userInfo"));
      const fetchData = async () => {
        dispatch({ type: "FRETCH_REQUEST" });
        try {
          const result = await axios.get(
            `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/GetThemen/${id}`,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          console.log(result.data);
          dispatch({
            type: "FETCH_SUCCESS",
            payload: result.data,
          });
        } catch (err) {
          dispatch({ type: "FETCH_FAIL", payload: err.message });
        }
      };
      fetchData();
    } else {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  return loading ? (
    <Container className="mt-3">
      <Row>
        <Col md={12}>
          <div className="center">
            <LoadingBox />
          </div>
        </Col>
      </Row>
    </Container>
  ) : error ? (
    <Container className="mt-3">
      <MessageBox>{error}</MessageBox>
    </Container>
  ) : (
    <div>
      <Helmet>
        <title>Home Page</title>
      </Helmet>
      {themes.length !== 0 ? (
        <Container>
          <Row>
            <Col>
              {location.pathname === "/theme/0" ? null : (
                <h3
                  className="mb-3 justify-content-center"
                  style={{
                    color: "#f5f5f5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  {mainTitle}
                </h3>
              )}
            </Col>
          </Row>
          <Row>
            {themes.map((theme) => (
              <Col
                className=" mx-auto d-flex justify-content-center mb-3 themes"
                lg={{ span: 3 }}
                sm={12}
                key={theme.ID}
              >
                <Theme theme={theme} route={`/theme/${theme.ID}`} />
              </Col>
            ))}
          </Row>
        </Container>
      ) : null}
    </div>
  );
}

export default HomeScreen;
