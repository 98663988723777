import Alert from 'react-bootstrap/Alert';
import React from 'react';

function MessageBox(props) {
  return (
    <Alert
      style={{ textAlign: 'center', color: '#dc9e1f' }}
      variant={`${props.variant}` || '#2e2e2e'}
    >
      {props.children}
    </Alert>
  );
}

export default MessageBox;
