import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function LoadingBox(props) {
  const { size, variant } = props;
  return (
    <Spinner
      animation="border"
      role="status"
      variant={`${variant}` || ''}
      size={`${size}` || ''}
      style={{ color: '#dc9e1f' }}
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default LoadingBox;
