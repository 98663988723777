import React from "react";
import { Container, Row } from "react-bootstrap";

const PageNotFound = () => {
  return (
    <Container>
      <div className="centerl">
        <Row>
          <h3 className="text">This page could not be found</h3>
        </Row>
        <Row>
          <img
            height={400}
            width={400}
            src="https://i.imgur.com/qIufhof.png"
            alt=""
          />
        </Row>
      </div>
    </Container>
  );
};

export default PageNotFound;
