import React, { useState } from "react";
import Axios from "axios";
import LoadingBox from "./LoadingBox";
import ProgressiveImage from "react-progressive-graceful-image";
import { Image } from "react-bootstrap";

function AnalyisTheme(props) {
  const [loading, setLoading] = useState(false);
  const toggleFavourit = async (id) => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await Axios.post(
      `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/ToggleFavorit`,
      {
        elementTyp: "Auswertung",
        elementId: `${id}`,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    setLoading(false);
    if (data === 1) {
      props.element.IsFavorit1 = !props.element.IsFavorit1;
    } else {
      props.element.IsFavorit1 = props.element.IsFavorit1;
    }
  };
  return (
    <div className="gallery" style={{ height: "160px" }}>
      {props.element.DateiName ? (
        <ProgressiveImage
          src={`//www.geoware-gmbh.de/ViewerBackend_Showcase/api/Img/${props.element.DateiName}`}
        >
          {(src, loading) => (
            <Image
              width={20}
              height={20}
              className={`image${loading ? " loading" : " loaded"}`}
              onClick={props.onClick}
              src={src}
              alt=""
            />
          )}
        </ProgressiveImage>
      ) : null}

      <div className="desc">
        {!loading ? (
          <i
            style={{ color: "red" }}
            onClick={() => toggleFavourit(props.element.ID)}
            className={
              props.element.IsFavorit1 ? "fas fa-heart" : "far fa-heart"
            }
          ></i>
        ) : (
          <LoadingBox variant="danger" size="sm" />
        )}
      </div>
    </div>
  );
}

export default AnalyisTheme;
