import React, { useContext, useEffect, useReducer, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Store } from "../Store";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import Tab from "@material-ui/core/Tab";
import MessageBox from "../Components/MessageBox";
import LoadingBox from "../Components/LoadingBox";
import axios from "axios";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";

const reducer = (state, action) => {
  switch (action.type) {
    case "FRETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, informations: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
function EvaluationInformationScreen() {
  const [selectedTab, setSelectedTab] = useState("0");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const { state } = useContext(Store);
  const { userInfo, mainTitle, subject } = state;
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    if (userInfo) {
      const token = JSON.parse(localStorage.getItem("userInfo"));
      const fetchData = async () => {
        dispatch({ type: "FRETCH_REQUEST" });
        try {
          const result = await axios.get(
            `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/GetInformationen/${id}`,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          dispatch({ type: "FETCH_SUCCESS", payload: result.data });
        } catch (err) {
          dispatch({ type: "FETCH_FAIL", payload: err.message });
        }
      };
      fetchData();
    } else {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  const [{ loading, informations, error }, dispatch] = useReducer(reducer, {
    informations: [],
    error: "",
    loading: true,
  });

  return (
    <div className="container-fluid">
      <Row>
        <Col md={2}>
          <h5 style={{ color: "#f5f5f5" }}>{mainTitle}</h5>
          <div
            style={{
              borderRadius: "0px",
              height: "8rem",
              backgroundColor: subject.backgroundColor,
              fontColor: subject.fontColor,
            }}
            className="card mt-3 "
          >
            <div className="card-body">
              <h5 className="card-title ">{subject.Name}</h5>
            </div>
          </div>
          <div className="pt-3 ">
            <ul className="list-group color-button mb-3">
              <button
                onClick={() => {
                  navigate(`/evaluationAnalysis/${id}`);
                }}
                style={{
                  padding: "8px 0px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item"
              >
                Auswertung
              </button>
              <button
                onClick={() => {
                  navigate(`/evaluationDocument/${id}`);
                }}
                style={{
                  padding: "8px 0px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item"
              >
                Dokumente
              </button>
              <button
                onClick={() => {
                  navigate(`/evaluationInformation/${id}`);
                }}
                style={{
                  padding: "8px 0px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item color-butto"
              >
                - Information -
              </button>
            </ul>
          </div>
        </Col>
        <Col className="height-col" 
        style={{
              position: "relative",
              backgroundColor: "#5e5e5e",
              color: "#f5f5f5",
              overflow: "auto",
              marginBottom: "3px",
            }}
             md={10}>
          <div
            
          >
            {loading ? (
              <Container className="mt-3">
                <Row>
                  <Col md={12}>
                    <div className="center">
                      <LoadingBox />
                    </div>
                  </Col>
                </Row>
              </Container>
            ) : error ? (
              <Container className="mt-3">
                <MessageBox variant="warning">{error}</MessageBox>
              </Container>
            ) : (
              <div className="mt-3">
                <Col lg={12} className="mb-3 ">
                  <Box
                    sx={{
                      width: "100%",
                      typography: "body1",
                    }}
                  >
                    {informations.length !== 0 ? (
                      <TabContext value={selectedTab}>
                        <Box
                          bgcolor="#5e5e5e"
                          component={"div"}
                          sx={{
                            mt: "0",
                            pt: "0",
                            position: "sticky",
                            top: "0px",
                            borderBottom: 1,
                            borderColor: "divider",
                          }}
                        >
                          <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            TabIndicatorProps={{
                              sx: { backgroundColor: "#dc9e1f", height: 2 },
                            }}
                          >
                            {informations.map((tab, index) => (
                              <Tab
                                variant="scrollable"
                                style={{ color: "#f5f5f5" }}
                                label={tab.Titel}
                                key={tab.ID}
                                value={index + ""}
                              />
                            ))}
                          </TabList>
                        </Box>
                        <div
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}
                        >
                          {informations.map((tab, index) => (
                            <TabPanel key={tab.ID} value={index + ""}>
                              {tab.Text}
                            </TabPanel>
                          ))}
                        </div>
                      </TabContext>
                    ) : null}
                  </Box>
                </Col>
              </div>
            )}
          </div>
        </Col>
        <Col md={2}></Col>
      </Row>
    </div>
  );
}
export default EvaluationInformationScreen;
