import React, { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Store } from "../Store";

function Theme(props) {
  const { dispatch } = useContext(Store);
  const { theme, route } = props;
  const location = useLocation();
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();

  function handlerClick() {
    if (location.pathname === "/theme/0") {
      dispatch({ type: "PUSH_MAIN_TITLE", payload: theme.Name });
      dispatch({ type: "PUSH_ID", payload: theme.ThemenID });
      localStorage.setItem("mainTitle", theme.Name);
      localStorage.setItem("ID", theme.ThemenID);

      navigate(route);
    } else if (location.pathname === `/theme/${id}`) {
      dispatch({
        type: "PUSH_SUBJECT",
        payload: {
          backgroundColor: theme.Hintergrundfarbe,
          fontColor: theme.Schriftfarbe,
          Name: theme.Name,
        },
      });
      localStorage.setItem("backgroundColor", theme.Hintergrundfarbe);
      localStorage.setItem("fontColor", theme.Schriftfarbe);
      localStorage.setItem("Name", theme.Name);
      navigate(`/evaluationAnalysis/${theme.ThemenID}`);
    }
  }

  return (
    <div
      onClick={handlerClick}
      className="card "
      style={{
        borderRadius: "0px",
        marginTop: `${location.pathname === "/theme/0" ? "4rem" : ""}`,
        cursor: "pointer",
        width: "16rem",
        backgroundColor: theme.Hintergrundfarbe,
        fontColor: theme.Schriftfarbe,
      }}
    >
      {theme.Hintergrundbildpfad ? (
        <img
          className="card-img-top"
          src={`//www.geoware-gmbh.de/ViewerBackend_Showcase/api/Img/${theme.Hintergrundbildpfad}`}
          alt=""
        />
      ) : null}
      <div className="card-body">
        <h5 className="card-title ">{theme.Name}</h5>
      </div>
    </div>
  );
}

export default Theme;
