import React, { useContext, useEffect, useReducer, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Store } from "../Store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DocumentTheme from "../Components/DocumentTheme";
import LoadingBox from "../Components/LoadingBox";
import MessageBox from "../Components/MessageBox";
import Container from "react-bootstrap/Container";

const reducer = (state, action) => {
  switch (action.type) {
    case "FRETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, documents: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "FETCH_SUCCESS_FAVOURITS":
      return { ...state, favourits: action.payload, loading: false };

    default:
      return state;
  }
};

function EvaluationDocumentScreen() {
  const [{ loading, documents, error }, dispatch] = useReducer(reducer, {
    documents: [],
    favourits: [],
    error: "",
    loading: true,
  });
  console.log(documents);
  const { state } = useContext(Store);
  const [description, setDescription] = useState("");
  const { userInfo, mainTitle, subject } = state;
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const location = useLocation();

  console.log(location.pathname);

  const handlerWithClick = (desc) => {
    setDescription(desc);
  };

  useEffect(() => {
    if (userInfo) {
      const token = JSON.parse(localStorage.getItem("userInfo"));
      const fetchData = async () => {
        dispatch({ type: "FRETCH_REQUEST" });
        try {
          const result = await axios.get(
            `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/GetDokumente/${id}`,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          dispatch({ type: "FETCH_SUCCESS", payload: result.data });
          const { data } = await axios.get(
            `https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/GetDokumente/${id}/Favorit
            `,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          dispatch({ type: "FETCH_SUCCESS_FAVOURITS", payload: data });
        } catch (err) {
          dispatch({ type: "FETCH_FAIL", payload: err.message });
        }
      };
      fetchData();
    } else {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  return (
    <div className="container-fluid">
      <Row>
        <Col md={2}>
          <h5 style={{ color: "#f5f5f5" }}>{mainTitle}</h5>
          <div
            style={{
              borderRadius: "0px",
              height: "8rem",
              backgroundColor: subject.backgroundColor,
              fontColor: subject.fontColor,
            }}
            className="card mt-3 "
          >
            <div className="card-body">
              <h5 className="card-title ">{subject.Name}</h5>
            </div>
          </div>
          <div className="pt-3 ">
            <ul className="list-group color-button mb-3">
              <button
                onClick={() => {
                  navigate(`/evaluationAnalysis/${id}`);
                }}
                style={{
                  padding: "8px 2px",
                  marginBottom: "2px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item"
              >
                Auswertung
              </button>
              <button
                onClick={() => {
                  navigate(`/evaluationDocument/${id}`);
                }}
                style={{
                  padding: "8px 2px",
                  marginBottom: "2px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item"
              >
                - Dockumente -
              </button>
              <button
                onClick={() => {
                  navigate(`/evaluationInformation/${id}`);
                }}
                style={{
                  padding: "8px 2px",
                  marginBottom: "2px",
                  border: "none",
                  backgroundColor: "#2e2e2e",
                  color: "#f5f5f5",
                  textAlign: "left",
                }}
                className="list-group-item color-butto"
              >
                Information
              </button>
            </ul>
          </div>
        </Col>
        <Col
          style={{
            position: "relative",
            backgroundColor: "#5e5e5e",
            color: "#f5f5f5",
            display: "flex",
            overflow: "auto",
            marginBottom: "3px",
          }}
          className="height-col"
          md={8}
        >
          {loading ? (
            <Container className="mt-3">
              <Row>
                <Col>
                  <div className="center">
                    <LoadingBox />
                  </div>
                </Col>
              </Row>
            </Container>
          ) : error ? (
            <Container className="mt-3">
              <MessageBox variant="warning">{error}</MessageBox>
            </Container>
          ) : (
            <Container>
              <div className="mt-3">
                <Row>
                  {documents.length !== 0
                    ? documents.map((document) => (
                        <Col key={document.ID} lg={6} sm={12} className="mb-3">
                          <DocumentTheme
                            onClick={() =>
                              handlerWithClick(document.Beschreibung)
                            }
                            document={document}
                          />
                        </Col>
                      ))
                    : null}
                </Row>
              </div>
            </Container>
          )}
        </Col>
        <Col md={2}>
          <h5 style={{ color: "#f5f5f5" }} className="m-auto">
            Beschreibung
          </h5>

          <textarea
            disabled
            value={description}
            style={{
              backgroundColor: "#5e5e5e",
              color: "#f5f5f5",
              marginBottom: "1rem",
            }}
            className="mt-3 form-control mb-3"
            id="textAreaExample1"
            rows="5"
          ></textarea>
        </Col>
      </Row>
    </div>
  );
}

export default EvaluationDocumentScreen;
