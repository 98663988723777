import React from "react";

function DocumentTheme(props) {
  return (
    <ul
      style={{
        margin: "2px",
        paddingLeft: "1px",
        listStyleType: "none",
        border: "0.5px dashed #f5f5f5",
      }}
    >
      <li
        className="list-item"
        style={{
          padding: "10px",
        }}
      >
        <i
          className="fa fa-file"
          style={{ fontSize: "24px", paddingLeft: "2px" }}
        ></i>
        <a
          style={{
            textDecoration: "none",
            color: "#f5f5f5",
            paddingLeft: "5px",
          }}
          href={`https://www.geoware-gmbh.de/ViewerBackend_Showcase/api/Doc/${props.document.DateiName}`}
        >
          {props.document.Name}
        </a>
      </li>
    </ul>
  );
}

export default DocumentTheme;
